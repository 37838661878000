<template>
  <Data
    :closeAndSave="true"
    :disableMenu="true"
    :shadow="false"
    :showMenu="false"
    @close="onCloseAndSave"
  >
    <div v-if="canShowProgressBar" class="mx-auto" style="max-width: 35rem">
      <ProgressBar current-step="3" />
    </div>

    <v-card class="pa-5 pa-md-10 mx-auto" max-width="800px" elevation="2">
      <v-row class="px-5" align="center" justify="center" no-gutters>
        <v-col cols="12" class="pb-5">
          <h1 class="font-weight-medium mb-0">
            {{ $t('two_factor_auth.two_factor_auth_heading') }}
          </h1>
        </v-col>

        <v-col cols="12" class="pb-5">
          <h2 class="font-weight-medium mb-0 text-center">
            {{ $t('placeholder.phone') }}
          </h2>
        </v-col>

        <v-col cols="12" class="pt-0 pb-5 px-0 px-sm-8 px-md-16 text-center">
          <span class="subtitle-1 font-weight-regular">
            {{ $t('two_factor_auth.enter_phone_number_text') }}
          </span>
        </v-col>

        <v-col cols="12" class="pt-0 py-10 text-center" style="max-width: 20rem">
          <form @submit.prevent="sendMobile()">
            <SPhoneNumberInput
              v-model="phoneNumber"
              :class="{ 'pb-5': !isRememberPossible }"
              autofocus
              required
              @on-validity-change="phoneNumberValid = $event"
            />

            <v-checkbox
              v-if="isRememberPossible"
              v-model="remember"
              :label="$t('two_factor_auth.save_for_next_time')"
            ></v-checkbox>

            <v-btn type="submit" color="primary" :loading="isSendingMobile" x-large block>
              {{ $t('two_factor_auth.send_pin') }}
            </v-btn>
          </form>
        </v-col>
      </v-row>
    </v-card>
  </Data>
</template>

<script>
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/ContractService.js';
import SPhoneNumberInput from '@/common/components/SPhoneNumberInput';

export default {
  name: 'ContractDocumentSign2FAStart',
  components: {
    SPhoneNumberInput,
    ProgressBar: () => import('@/components/ProgressBar'),
  },
  data() {
    return {
      isSendingMobile: false,
      phoneNumber: '',
      phoneNumberValid: true,
      remember: false,
    };
  },
  computed: {
    ...mapGetters({
      contract: 'contract',
      myIdentity: 'myIdentity',
      profile: 'profile',
    }),
    canShowProgressBar() {
      return this.$route.query.progress;
    },
    contractId() {
      return this.$route.params.contract_id;
    },
    isRememberPossible() {
      return this.myIdentity && this.myIdentity.is_proposer;
    },
    workspaceId() {
      return this.$route.params.workspace_id;
    },
    phoneSettings() {
      return {
        mobile: this.phoneNumber,
        remember: this.remember,
      };
    },
  },
  watch: {
    profile(newVal) {
      if (newVal) {
        this.loadMobile();
      }
    },
  },
  created() {
    ContractService.detail(this.contractId, this.workspaceId).then(
      (response) => {
        this.$store.commit('setContract', response);
        this.loadMobile();
      },
      () => {
        this.$notification.error(this.$t('contract.not_loaded'));
      },
    );
  },
  methods: {
    loadMobile() {
      const identityMobile = this.myIdentity ? this.myIdentity.mobile : null;
      const isNotOnedevice = !this.contract || !this.contract.one_device;
      const profileMobile =
        this.profile && isNotOnedevice ? this.profile.mobile || this.profile.mobile_tmp : null;
      this.phoneNumber = identityMobile || profileMobile || this.phoneNumber || '';
    },
    sendMobile() {
      if (!this.myIdentity) {
        return this.$router.push({
          name: 'documentsDetail',
          params: {
            workspace_id: this.$route.params.workspace_id,
            contract_id: this.$route.params.contract_id,
          },
        });
      }

      this.isSendingMobile = true;

      if (!this.phoneNumberValid) {
        this.$notification.error(this.$t('error.phone_number.bad_format'));
        this.isSendingMobile = false;

        return;
      }

      ContractService.sendPhoneAuthorized(
        this.phoneSettings,
        this.contractId,
        this.myIdentity.id,
      ).then(
        (response) => {
          this.$router.push({
            name: 'contractDocumentSign2FASubmit',
            params: {
              contract_id: this.$route.params.contract_id,
              receivedPinCode: response.smsPin,
              workspace_id: this.$route.params.workspace_id,
            },
            query: this.$route.query.progress ? { progress: 1 } : null,
          });
          this.isSendingMobile = false;
        },
        (errorCode) => {
          this.isSendingMobile = false;
          this.$notification.error(
            403 === errorCode
              ? this.$t('contract.errors.access_2fa')
              : this.$t('contract.errors.send_pin_on_mobile'),
          );
        },
      );
    },
    onCloseAndSave() {
      return this.$router.push({
        name: 'documentsDetail',
        params: {
          workspace_id: this.workspaceId,
          contract_id: this.contractId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
